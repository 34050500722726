<!-- eslint-disable vue/no-v-html -->
<template>
	<v-layout column fill-height>
		<v-layout v-if="pageLoading" align-center fill-height justify-center>
			<v-progress-circular color="primary" indeterminate size="50" width="6" />
		</v-layout>
		<NoWorkspaceAvailable v-else-if="isHoldingEmpty" />
		<template v-else>
			<v-flex shrink>
				<v-toolbar dense>
					<w-search-input v-model="filters.search" :placeholder="$t('workspaces.search_for_a_workspace')" />
					<w-btn flat icon="fas fa-filter" mini small @click="showFilterMenu">{{ $t('actions.filter') }}</w-btn>
					<v-menu v-model="displayFilterMenu" absolute offset-y :position-x="filterMenuCoordinates.x" :position-y="filterMenuCoordinates.y">
						<v-list>
							<v-list-tile>
								<w-list-tile-content style="min-height: 60px">
									<w-switch v-model="filters.with_trashed" :label="$t('workspaces.display_deleted_workspaces')" :ripple="false" />
								</w-list-tile-content>
							</v-list-tile>
						</v-list>
					</v-menu>
					<v-spacer />
					<v-divider vertical />
					<w-btn icon="add" :mini="$vuetify.breakpoint.xsOnly" :small="$vuetify.breakpoint.xsOnly" @click="openAccountingFirmForm()">{{
						$t('workspaces.create_a_workspace')
					}}</w-btn>
				</v-toolbar>
			</v-flex>
			<v-flex scroll-y>
				<v-layout justify-center row>
					<v-flex shrink style="width: 750px">
						<v-data-table
							class="elevation-1"
							:headers="headers"
							:items="accountingFirms"
							:loading="accountingFirmsLoading"
							:no-data-text="noDataText"
							@click.native.stop
						>
							<template v-slot:items="{ item: accountingFirm }">
								<td
									:class="{
										'crossed--text': accountingFirm.deleted_at,
										pointer: true,
										'red--text': accountingFirm.deleted_at
									}"
									@click="openAccountingFirmForm(accountingFirm)"
									v-html="$highlightMatching(accountingFirm.name, filters.search)"
								/>
								<td
									:class="{
										'crossed--text': accountingFirm.deleted_at,
										pointer: true,
										'red--text': accountingFirm.deleted_at,
										'text-xs-center': true
									}"
									@click="openAccountingFirmForm(accountingFirm)"
								>
									{{ accountingFirm.domain }}
								</td>
							</template>
						</v-data-table>
					</v-flex>
				</v-layout>
			</v-flex>
			<router-view />
		</template>
	</v-layout>
</template>

<script>
import WorkspaceModuleGuard from '@/mixins/ModulesGuards/Workspaces/WorkspaceModuleGuard'

export default {
	name: 'WorkspaceManager',
	components: {
		NoWorkspaceAvailable: () => ({
			component: import('@/components/Holding/NoWorkspaceAvailable')
		})
	},
	mixins: [WorkspaceModuleGuard],
	inject: ['holdingContext'],
	data: function () {
		return {
			accountingFirms: [],
			accountingFirmsLoading: false,
			displayFilterMenu: false,
			filters: {
				search: '',
				with_trashed: false
			},
			filterMenuCoordinates: {
				x: 0,
				y: 0
			},
			headers: [
				{
					sortable: true,
					text: this.$tc('workspaces.workspace', 1),
					value: 'name'
				},
				{
					align: 'center',
					sortable: false,
					text: this.$tc('workspaces.domain', 1)
				}
			],
			isHoldingEmpty: false,
			pageLoading: false,
			searchTimeout: null
		}
	},
	computed: {
		holdingId: function () {
			return this.holdingContext?.holding_id
		},
		noDataText: function () {
			let result = null
			if (this.accountingFirmsLoading) {
				result = this.$t('workspaces.loading-progress')
			} else if (this.filters.search) {
				result = this.$t('workspaces.no-data-found-for-search', { search: this.filters.search })
			} else if (!this.filters.with_trashed) {
				result = this.$t('workspaces.no-active-data-found')
			} else {
				result = this.$t('workspaces.no-data-found')
			}
			return result
		}
	},
	watch: {
		'filters.search': {
			handler: 'onSearchModified'
		},
		'filters.with_trashed': {
			handler: function (with_trashed) {
				this.loadAccountingFirms({ with_trashed })
			}
		},
		holdingId: {
			handler: 'loadPageData'
		}
	},
	mounted: function () {
		if (this.holdingId) {
			this.loadPageData()
		}
	},
	methods: {
		getModuleEventsActionsMapping: function () {
			return [
				{ event: this.events.ACCOUNTING_FIRM_ARCHIVED, action: this.onAccountingFirmArchived },
				{ event: this.events.ACCOUNTING_FIRM_CREATED, action: this.onAccountingFirmCreated },
				{ event: this.events.ACCOUNTING_FIRM_DELETED, action: this.onAccountingFirmDeleted },
				{ event: this.events.ACCOUNTING_FIRM_MODIFIED, action: this.onAccountingFirmModified },
				{ event: this.events.ACCOUNTING_FIRM_REACTIVATED, action: this.onAccountingFirmReactivated }
			]
		},
		loadAccountingFirms: function (filters = {}) {
			this.accountingFirmsLoading = true
			if (!filters.hasOwnProperty('search') && this.filters.search) {
				filters.search = this.filters.search
			}
			if (!filters.hasOwnProperty('with_trashed') && this.filters.with_trashed) {
				filters.with_trashed = true
			}
			return this.service
				.listAccountingFirms(this.holdingId, {
					...filters,
					fields: ['id', 'name', 'domain', 'deleted_at']
				})
				.then(accountingFirms => {
					this.accountingFirms = accountingFirms
				})
				.finally(() => {
					this.accountingFirmsLoading = false
				})
		},
		loadPageData: function () {
			this.pageLoading = true

			return this.loadAccountingFirms()
				.then(() => {
					if (this.accountingFirms.length > 0) {
						return
					}
					return this.loadAccountingFirms({ with_trashed: true }).then(() => {
						if (this.accountingFirms.length == 0) {
							this.isHoldingEmpty = true
						} else {
							this.isHoldingEmpty = false
							this.accountingFirms.splice(0, this.accountingFirms.length)
						}
					})
				})
				.finally(() => {
					this.pageLoading = false
				})
		},
		onAccountingFirmArchived: function (accountingFirm) {
			const index = this.accountingFirms.findIndex(({ id }) => id == accountingFirm.id)
			if (index == -1) {
				return
			}

			if (this.filters.with_trashed) {
				this.accountingFirms[index].deleted_at = accountingFirm.deleted_at
			} else {
				this.accountingFirms.splice(index, 1)
			}
		},
		onAccountingFirmCreated: function (accountingFirm) {
			this.accountingFirms.push(accountingFirm)
		},
		onAccountingFirmDeleted: function (accountingFirm) {
			const index = this.accountingFirms.findIndex(({ id }) => id == accountingFirm.id)
			if (index != -1) {
				this.accountingFirms.splice(index, 1)
			}
		},
		onAccountingFirmModified: function (accountingFirm) {
			const index = this.accountingFirms.findIndex(({ id }) => id == accountingFirm.id)
			if (index != -1) {
				this.accountingFirms[index].domain = accountingFirm.domain
				this.accountingFirms[index].name = accountingFirm.name
			}
		},
		onAccountingFirmReactivated: function (accountingFirm) {
			const index = this.accountingFirms.findIndex(({ id }) => id == accountingFirm.id)

			if (this.filters.with_trashed && index != -1) {
				this.accountingFirms[index].deleted_at = null
			} else {
				this.loadAccountingFirms()
			}
		},
		onSearchModified: function () {
			if (this.searchTimeout) {
				clearTimeout(this.searchTimeout)
				this.searchTimeout = null
			}

			this.searchTimeout = setTimeout(
				function () {
					this.loadAccountingFirms()
				}.bind(this),
				1000
			)
		},
		openAccountingFirmForm: function (accountingFirm) {
			this.appService.goTo({
				name: 'workspaces-workspace',
				params: {
					workspaceId: accountingFirm ? accountingFirm.id : 'create'
				}
			})
		},
		showFilterMenu: function (clickEvent) {
			this.filterMenuCoordinates.x = clickEvent.clientX
			this.filterMenuCoordinates.y = clickEvent.clientY
			this.displayFilterMenu = true
		}
	}
}
</script>